<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle"></app-banner>
<main id="main" role="main">
	<div class="container">
		<ul class="news">
			<li *ngFor="let post of posts" class="news-item">
				<div class="date">{{ post.date | date: 'dd.MM.yyyy' }}</div>
				<h2><a [routerLink]="['/post', post.slug]">{{ post.title }}</a></h2>
				<div [innerHtml]="post.excerpt.rendered"></div>
				<a [routerLink]="['/post', post.slug]" class="read-more">{{ 'READMORE' | translate }}</a>
			</li>
		</ul>

		<mat-paginator 
			[length]="postCount"
			[pageSize]="pageSize"
			[pageIndex]="pageIndex"
			(page)="onChangePage($event)">
		</mat-paginator>
	</div>
</main>