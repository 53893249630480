<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle"></app-banner>
<main id="main" role="main">
	<div *ngIf="post" class="news-item container">

		<h2>{{ post.title.rendered }}</h2>

		<div class="date">{{ post.date | date: 'dd.MM.yyyy'}}</div>
		<div [innerHtml]="post.content.rendered"></div>

		<div class="m-t-20 pull-right">
			<a [routerLink]="'/posts'" class="btn btn-primary">{{ 'NEWSLIST' | translate }}</a>
		</div>
	</div>

</main>