<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle"></app-banner>
<main id="main" role="main">
	<div class="container">
		<div id="practice-areas">
			<ul>
				<li *ngFor="let pa of practiceAreas">
					<ng-container *ngIf="_selectedLanguage == 'is'">
						<h2><a [routerLink]="['/practice-areas', pa.slug]">{{ pa.title.rendered }}</a></h2>
					</ng-container>
					<ng-container *ngIf="_selectedLanguage == 'en'">
						<h2><a [routerLink]="['/practice-areas', pa.slug]">{{ pa.acf['title_english'] }}</a></h2>
					</ng-container>
				</li>
			</ul>

		</div>
	</div>
</main>