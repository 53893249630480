import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PostService } from 'src/app/services/posts/post.service';

@Component({
	selector: 'app-post-detail',
	templateUrl: './post-detail.component.html',
	styleUrls: ['./post-detail.component.less'],
	providers: [PostService]
})
export class PostDetailComponent implements OnInit {

	private _selectedLanguage: string;
	bannerText: string;
	backgroundStyle: Object;
	post: any;

	constructor(private postService: PostService,
		private translate: TranslateService,
		private route: ActivatedRoute) {
		this._selectedLanguage = translate.currentLang;
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('NEWS.TITLE')
		});
	}

	public getPost = (slug: string) => {
		this.postService.getPost(slug).subscribe((data: any) => {
			this.post = data[0]
		})
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('NEWS.TITLE');
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/logosticker2.jpg)',
        }
		const slug = this.route.snapshot.paramMap.get('slug');
		this.getPost(slug);
	}

}
