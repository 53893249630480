import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PracticeAreasService {

	private _wpBase = environment.apiBaseUrl + 'wp-json/wp/v2/';

	constructor(private httpClient: HttpClient) { }

	public getPracticeAreas = (limit: number) => {

		let query = this._wpBase + 'practice-areas';

		if (limit && limit > 0) {
			query += '?per_page=' + limit;
		}

		return this.httpClient.get(query)
			
	}

	public getPracticeArea = slug => this.httpClient.get(this._wpBase + `practice-areas?slug=${slug}`)
	
}
