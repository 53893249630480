import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PracticeAreasService } from '../practice-areas.service';

@Component({
	selector: 'app-practice-area',
	templateUrl: './practice-area.component.html',
	styleUrls: ['./practice-area.component.less'],
	providers: [PracticeAreasService]
})
export class PracticeAreaComponent implements OnInit {

	public _selectedLanguage: string;
	bannerText: string;
	backgroundStyle: Object;
	practiceArea: any;

	constructor(
		private practiceAreaService: PracticeAreasService,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		this._selectedLanguage = translate.currentLang;
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('PRACTICEAREAS')
		});
	}

	public getPracticeArea = (slug: string) => {
		this.practiceAreaService.getPracticeArea(slug).subscribe((data: any[]) => {
			this.practiceArea = data[0]
		})
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('PRACTICEAREAS')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/stolar2.jpg)',
            'background-position': '50%',
            'background-attachment': 'inherit',
            'background-size': 'auto',
        }
		const slug = this.route.snapshot.paramMap.get('slug');
		this.getPracticeArea(slug);
	}

}
