import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { EmployeeService } from '../employee-service'

@Component({
	selector: 'app-employee-list',
	templateUrl: './employee-list.component.html',
	styleUrls: ['./employee-list.component.less'],
	providers: [EmployeeService]
})
export class EmployeeListComponent implements OnInit {

	public _selectedLanguage: string;
	bannerText: string;
	backgroundStyle: Object;
	employees = []

	constructor(
		public translate: TranslateService,
		private router: Router,
		private employeeService: EmployeeService) { 

		this._selectedLanguage = translate.currentLang
        this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('EMPLOYEES.EMPLOYEES')
        });
	}

	public getEmployees = () => {
		this.employeeService.getEmployees().subscribe((data: any[]) => this.employees = data )
	}

	public selectEmployee = (slug:string) => {
		this.router.navigate([this.translate.currentLang + '/' + this.translate.instant('EMPLOYEE'), slug]);
	  }

	ngOnInit() {
		this.bannerText = this.translate.instant('EMPLOYEES.EMPLOYEES')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/kufl.jpg)',
		}
		this.getEmployees();
	}

}
