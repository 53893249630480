<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle" [hasShade]="true"></app-banner>
<main id="main" role="main">
    <div class="container">

        <div id="employees">
            <ng-container *ngFor="let employee of employees; let idx = index;">
                <div class="row" *ngIf="idx % 3 == 0">

                    <ng-container *ngFor="let i of [idx, idx + 1, idx + 2]">
                        <div class="col-md-6 col-lg-4" *ngIf="employees[i] != null">
                            <article class="employee">
                                <figure class="image">
                                    <img src="{{ employees[i]._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url }}" alt="{{ employees[i].title.rendered }}">
                                </figure>
                                <aside>
                                    <h2>{{ employees[i].title.rendered }}</h2>
                                    <div class="position">
                                        <ng-container *ngIf="_selectedLanguage == 'is' && employees[i].acf['entitlement_icelandic']"><span>{{ employees[i].acf['entitlement_icelandic'] }}</span></ng-container>
                                        <ng-container *ngIf="_selectedLanguage == 'en' && employees[i].acf['entitlement_english']"><span>{{ employees[i].acf['entitlement_english'] }}</span></ng-container>

                                        <ng-container *ngIf="_selectedLanguage == 'is'">
                                            <ng-container *ngIf="!employees[i].acf['entitlement_icelandic']">{{ employees[i].acf['jobtitle_icelandic'] }}</ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="_selectedLanguage == 'en'">
                                            <ng-container *ngIf="!employees[i].acf['entitlement_english']">{{ employees[i].acf['jobtitle_english'] }}</ng-container>
                                        </ng-container>
                                    </div>
                                    <a href="mailto:{{ employees[i].acf['email'] }}" class="email">{{ employees[i].acf['email'] }}</a>
                                    <a [routerLink]="['/employee', employees[i].slug]" class="employee-details">{{'EMPLOYEES.MORE' | translate}}</a>
                                </aside>
                            </article>
                        </div>
                    </ng-container>

                </div>
            </ng-container>
        </div>
    </div>
</main>
