import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {

	bannerText: string;
	image: string;
	backgroundStyle: Object;

	constructor(public translate: TranslateService) {
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this.bannerText = this.translate.instant('CONTACTUS')
		});
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('CONTACTUS')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/kufl.jpg)',
		};
	}

}
