import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-notFound',
	templateUrl: './notFound.component.html',
	styleUrls: ['./notFound.component.less']
})
export class NotFoundComponent implements OnInit {

	bannerText: string;

	constructor(public translate: TranslateService) {
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this.bannerText = this.translate.instant('PAGENOTFOUNDTITLE')
		});
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('PAGENOTFOUNDTITLE')
	}

}
