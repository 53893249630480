import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class HomeService {

	private _wpBase = environment.apiBaseUrl + 'wp-json/wp/v2/';

	constructor(private httpClient: HttpClient) { }

	public getFrontPageQuote(lang: string) {
		var query = "";
		if (lang == 'is') {
			query = 'posts?filter[category_name]=frontpage_quote_icelandic';
		} else {
			query = 'posts?filter[category_name]=frontpage_quote_english';
		}
		return this.httpClient.get(this._wpBase + query)
	}

	public getFrontPageIntroductionText(lang: string) {

		var query = "";
		if (lang == 'is') {
			query = 'posts?filter[category_name]=frontpage_introduction_icelandic';
		} else {
			query = 'posts?filter[category_name]=frontpage_introduction_english';
		}
		return this.httpClient.get(this._wpBase + query)
	}

}
