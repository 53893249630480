import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AboutService {

	private _wpBase = environment.apiBaseUrl + 'wp-json/wp/v2/';

	constructor(private httpClient: HttpClient) { }


	public getAbouts = () => {
		return this.httpClient.get(this._wpBase + 'about');
	}

	public getAbout = slug => {
		return this.httpClient.get(this._wpBase + `about?slug=${slug}`);
	} 

}
