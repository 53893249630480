<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle" [hasShade]="true"></app-banner>
<main id="main" role="main">
	<div *ngIf="employee" class="container">

		<article class="card">

			<div class="row">

				<div class="col-md-3">
					<img src="{{employee._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}}" />
				</div>

				<div class="col-md-9">

					<header>
						<h2>
							{{ employee.title.rendered }}
							<ng-container *ngIf="_selectedLanguage == 'is' && employee.acf['jobtitle_icelandic']">
								<span class="jobtitle">{{ employee.acf['jobtitle_icelandic'] }}</span>
							</ng-container>
							<ng-container *ngIf="_selectedLanguage == 'en' && employee.acf['jobtitle_english']">
								<span class="jobtitle">{{ employee.acf['jobtitle_english'] }}</span>
							</ng-container>
						</h2>
					</header>
					<div class="contact-details">
						<ng-container *ngIf="_selectedLanguage == 'is' && employee.acf['entitlement_icelandic']">
							<div class="position">{{ employee.acf['entitlement_icelandic'] }}</div>
						</ng-container>
						<ng-container *ngIf="_selectedLanguage == 'en' && employee.acf['entitlement_english']">
							<div class="position">{{ employee.acf['entitlement_english'] }}</div>
						</ng-container>
						<a href="mailto:{{ employee.acf['email']}}" class="email">{{ employee.acf['email'] }}</a>
						<ng-container *ngIf="employee.acf['telephone']">
							<span class="tel"><i class="icon ion-ios-telephone"></i>+354
								{{ employee.acf['telephone'] }}</span>
						</ng-container>
					</div>
					<div class="description">

						<ng-container *ngIf="_selectedLanguage == 'is'">
							<div [innerHtml]="employee.content.rendered"></div>
						</ng-container>
						<ng-container *ngIf="_selectedLanguage == 'en'">
							<div [innerHtml]="employee.acf['description_english']"></div>
						</ng-container>

					</div>

				</div>

			</div><!-- end row -->
		</article>


		<div class="m-t-20 pull-right">
			<a [routerLink]="'/employees'" class="btn btn-primary">{{ 'EMPLOYEELIST' | translate }}</a>
		</div>


	</div>
</main>