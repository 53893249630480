<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle"></app-banner>
<main id="main" role="main">
    <div class="container">
        <div *ngFor="let a of about">
            <ng-container *ngIf="_selectedLanguage == 'is'">
                <div [innerHtml]="a.content.rendered"></div>
            </ng-container>
            <ng-container *ngIf="_selectedLanguage == 'en'">
                <div [innerHtml]="a.acf['about_ius_description_english']"></div>
            </ng-container>
        </div>
    </div>
</main>
