<app-banner [title]="bannerText" [image]="image" [backgroundStyle]="backgroundStyle" [hasShade]="true"></app-banner>
<main id="main" role="main">
	<div class="container">
		<p>{{ 'CONTACTUSTEXT' | translate }}</p>
		<ul class="contacts">
			<li>
				<h3>Garðar G. Gíslason</h3>
				<div class="tel"><i class="icon ion-ios-telephone"></i>896 2668</div>
				<a href="mailto:gardar@iuslaw.is" class="mail"><i class="icon ion-email"></i>gardar@iuslaw.is</a>
			</li>
			<li>
				<h3>Eleonora Bergþórsdóttir</h3>
				<div class="tel"><i class="icon ion-ios-telephone"></i>862 9763</div>
				<a href="mailto:eleonora@iuslaw.is" class="mail"><i class="icon ion-email"></i>eleonora@iuslaw.is</a>
			</li>
			<li>
				<h3>Ágúst Karl Karlsson</h3>
				<div class="tel"><i class="icon ion-ios-telephone"></i>699 1404</div>
				<a href="mailto:agust@iuslaw.is" class="mail"><i class="icon ion-email"></i>agust@iuslaw.is</a>
			</li>
		</ul>

	</div>
</main>