import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AboutService } from './about.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.less'],
    providers: [ AboutService ]
})
export class AboutComponent implements OnInit {

    public _selectedLanguage: string;

    about = []
    bannerText: string;
    image: string;
    backgroundStyle: Object;

    constructor(
        public translate: TranslateService,
        private aboutService: AboutService
    ) {
        this._selectedLanguage = translate.currentLang
        this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
            this._selectedLanguage = params.lang;
            this.bannerText = this.translate.instant('ABOUT.TITLE')
            
        });
    }

    ngOnInit() {
        this.bannerText = this.translate.instant('ABOUT.TITLE')

        this.backgroundStyle = {
            'background-image': 'url(assets/images/backgrounds/stolar2.jpg)',
            'background-position': '50%',
            'background-attachment': 'inherit',
            'background-size': 'auto',
        }

        this.aboutService.getAbouts().subscribe((data: any[]) => {
            this.about = data
        })
    }

}
