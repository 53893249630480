import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})

export class AppComponent {
    opened: boolean;
    
    constructor(public translate: TranslateService) {
        translate.addLangs(["is", "en"]);
        translate.setDefaultLang('is');
        translate.use('is')
        this.opened = false
        
    }
    title = 'ius-universal';

    useLanguage(language: string) {
        this.translate.use(language);
    }
}
