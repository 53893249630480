import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class EmployeeService {

	private _wpBase = environment.apiBaseUrl + 'wp-json/wp/v2/';

	constructor(private httpClient: HttpClient) { }

	public getEmployees = () => this.httpClient.get(this._wpBase + 'employees?_embed')

	public getEmployee = (slug:string) => this.httpClient.get(this._wpBase + `employees?_embed&slug=${slug}`)
	  
}
