<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle" [isLarge]="true"></app-banner>
<main id="main" role="main">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="left-container">
					<blockquote>
						<div *ngFor="let q of quotes">
							<div [innerHtml]="q.content.rendered"></div>
						</div>
					</blockquote>
					<div *ngFor="let i of introText">
						<div [innerHtml]="i.content.rendered" class="intro-text"></div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="practice-areas">
					<h2>{{ 'PRACTICEAREAS' | translate }}</h2>
					<ul>
						<li *ngFor="let pa of practiceAreas">
							<ng-container *ngIf="_selectedLanguage == 'is'">
								<h3><a [routerLink]="['practice-areas', pa.slug]">{{ pa.title.rendered }}</a>
								</h3>
							</ng-container>
							<ng-container *ngIf="_selectedLanguage == 'en'">
								<h3><a [routerLink]="['practice-areas', pa.slug]">{{ pa.acf['title_english'] }}</a>
								</h3>
							</ng-container>
						</li>
					</ul>
					<div class="m-t-30">
						<a [routerLink]="'/practice-areas'"
						class="btn btn-lg">{{ 'PRACTICEAREALIST' | translate }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>