import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PracticeAreasListComponent } from './practice-areas/practice-areas-list/practice-areas-list.component';
import { PracticeAreaComponent } from './practice-areas/practice-area/practice-area.component';
import { PostListComponent } from './posts/post-list/post-list.component';
import { PostDetailComponent } from './posts/post-detail/post-detail.component';
import { EmployeeListComponent } from './employees/employee-list/employee-list.component';
import { EmployeeDetailComponent } from './employees/employee-detail/employee-detail.component';
import { NotFoundComponent } from './notFound/notFound.component';

export const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'practice-areas',
		component: PracticeAreasListComponent
	},
	{
		path: 'practice-areas/:slug',
		component: PracticeAreaComponent
	},
	{
		path: 'posts',
		component: PostListComponent
	},
	{
		path: 'post/:slug',
		component: PostDetailComponent
	},
	{
		path: 'employees',
		component: EmployeeListComponent
	},
	{
		path: 'employee/:slug',
		component: EmployeeDetailComponent
	},
	{
		path: 'about',
		component: AboutComponent
	},
	{
		path: 'contact',
		component: ContactComponent
	},
	{
		path: '404',
		component: NotFoundComponent
	},
/* 	{
		path: '**',
		component: NotFoundComponent
	},
	{
		path: '**',
		redirectTo: '/',
		pathMatch: 'full'
	} */

];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' });
