<mat-sidenav-container>
    <mat-sidenav #sidenav mode="push" [(opened)]="opened">
        <nav id="mobile-menu" class="panel" role="navigation">
            <div class="logo">
                <div class="upper">IUS</div>
                <div class="lower">Lawfirm</div>
            </div>
            <ul>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/employees'"
                        [routerLinkActive]="['router-link-active']" (click)="sidenav.toggle()">{{'NAVIGATION.EMPLOYEES' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/practice-areas'"
                        [routerLinkActive]="['router-link-active']" (click)="sidenav.toggle()">{{'NAVIGATION.PRACTICE-AREAS' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/posts'"
                        [routerLinkActive]="['router-link-active']" (click)="sidenav.toggle()">{{'NAVIGATION.NEWS' | translate}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'/about'"
                        [routerLinkActive]="['router-link-active']" (click)="sidenav.toggle()">{{'NAVIGATION.ABOUT'| translate}} IUS</a>
                </li>
            </ul>
            <div class="language">
                <h3>Choose language</h3>
                <ul class="language-select">
                    <li *ngFor="let lang of translate.getLangs()">
                        <a href="javascript:;" (click)="useLanguage(lang)">{{ lang }}</a>
                    </li>
                </ul>
            </div>
        </nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <header id="main-header">
            <div class="navbar">
                <div class="container">
                    <button class="hamburger hamburger--squeeze mat-button mobile-nav-btn" [ngClass]="{'is-active': opened }" (click)="sidenav.toggle()">
                        <div class="hamburger-box">
                            <div class="hamburger-inner"></div>
                        </div>
                    </button>
                    <div class="nav-content">
                        <div class="nav-wrap">
                            <nav class="site-navigation">
                                <ul class="nav-menu">
                                    <li class="nav-item">
                                        <a class="nav-link" [routerLink]="'/employees'"
                                            [routerLinkActive]="['router-link-active']">{{'NAVIGATION.EMPLOYEES' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" [routerLink]="'/practice-areas'"
                                            [routerLinkActive]="['router-link-active']">{{'NAVIGATION.PRACTICE-AREAS' | translate}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="nav-wrap wrap-logo">
                            <a class="nav-link logo-container" [routerLink]="'/'"
                                [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}">
                                <div class="logo">
                                    <div class="upper">IUS</div>
                                    <div class="lower">{{ 'LAWFIRM' | translate }}</div>
                                </div>
                            </a>
                        </div>
                        <div class="nav-wrap">
                            <nav class="site-navigation">
                                <ul class="nav-menu">
                                    <li class="nav-item">
                                        <a class="nav-link" [routerLink]="'/posts'"
                                            [routerLinkActive]="['router-link-active']">{{'NAVIGATION.NEWS' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" [routerLink]="'/about'"
                                            [routerLinkActive]="['router-link-active']">{{'NAVIGATION.ABOUT' | translate}}
                                            IUS</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <ul class="language-select">
                    <li *ngFor="let lang of translate.getLangs()" [ngClass]="{ 'is-active': lang == translate.currentLang }">
                        <a href="javascript:;" (click)="useLanguage(lang)">{{ lang }}</a>
                    </li>
                </ul>
            </div>
        </header>

        <div class="main-content">
            <router-outlet></router-outlet>
        </div>

        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h4><strong>IUS </strong> <span>{{ 'LAWFIRM' | translate }}</span></h4>
                        <address>
                            <div class="loc">Urriðaholtsstræti 2-4 &nbsp; | &nbsp; 3. hæð &nbsp; | &nbsp; 210 Garðabær</div>
                            <a href="https://goo.gl/maps/X8AAeCSoLaUhrFtW7" target="_blank"
                                class="see-map"><i class="icon ion-location"></i> {{ 'SEEMAP'| translate }}</a>
                        </address>
                    </div>
                    <div class="col-md-6">
                        <div class="mail pull-right"><a href="javascript:;" routerLink="/contact"><i
                                    class="icon ion-email"></i><span>{{ 'CONTACTUS' | translate }}</span></a></div>
                    </div>
                </div>

            </div>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>