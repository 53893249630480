import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from './home.service';
import { PracticeAreasService } from '../practice-areas/practice-areas.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.less'],
	providers: [ HomeService, PracticeAreasService ]
})
export class HomeComponent implements OnInit {

	public testBrowser: boolean;
	public _selectedLanguage: string;

	quotes = []
	introText = []
	practiceAreas = []
	bannerText: string;
	backgroundStyle: Object;

	constructor(
		@Inject(PLATFORM_ID) platformId: string,
		private homeService: HomeService,
		private practiceAreasService: PracticeAreasService,
		public translate: TranslateService) {
			
			this.testBrowser = isPlatformBrowser(platformId);
			this._selectedLanguage = translate.currentLang

			this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.getFrontPageQuote()
			this.getFrontPageIntroductionText()
			this.bannerText = this.translate.instant('FRONTPAGEBANNERQUOTE')
		});
	}

	public getFrontPageQuote() {
		this.homeService.getFrontPageQuote(this.translate.currentLang).subscribe((data: any[]) => {
			this.quotes = data
		})
	}

	public getFrontPageIntroductionText() {
		this.homeService.getFrontPageIntroductionText(this.translate.currentLang).subscribe((data: any[]) => {
			this.introText = data
		})
	}

	public getPracticeAreas() {
		this.practiceAreasService.getPracticeAreas(5).subscribe((data: any[]) => {
			this.practiceAreas = data
		})
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('FRONTPAGEBANNERQUOTE')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/joshua-sortino-CTVGEm6V8qI-unsplash.jpg)',
		}

		if (this.testBrowser) {
			this.getFrontPageQuote()
			this.getFrontPageIntroductionText()
			this.getPracticeAreas()
		}
		
	}
}
