import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { PostListComponent } from './posts/post-list/post-list.component';
import { PracticeAreasListComponent } from './practice-areas/practice-areas-list/practice-areas-list.component';
import { PracticeAreaComponent } from './practice-areas/practice-area/practice-area.component';
import { NotFoundComponent } from './notFound/notFound.component';
import { PostDetailComponent } from './posts/post-detail/post-detail.component';
import { EmployeeListComponent } from './employees/employee-list/employee-list.component';
import { EmployeeDetailComponent } from './employees/employee-detail/employee-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BannerComponent } from './banner/banner.component';
import { IusPaginator } from './utils/ius-paginator';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
  }
  
@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        ContactComponent,
        HomeComponent,
        PostListComponent,
        PracticeAreasListComponent,
        PracticeAreaComponent,
        NotFoundComponent,
        PostDetailComponent,
        EmployeeListComponent,
        EmployeeDetailComponent,
        BannerComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        /* RouterModule.forRoot(
          appRoutes,
          { enableTracing: true }
        ) */
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatPaginatorModule
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: IusPaginator}],
    bootstrap: [AppComponent]
})
export class AppModule { }
