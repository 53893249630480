<app-banner [title]="bannerText" [backgroundStyle]="backgroundStyle"></app-banner>
<main id="main" role="main">
	<div *ngIf="practiceArea" class="container">
		<article>
			<header>
				<h2>
					<ng-container *ngIf="_selectedLanguage == 'is'">
						{{ practiceArea.title.rendered }}
					</ng-container>
					<ng-container *ngIf="_selectedLanguage == 'en'">
						{{ practiceArea.acf['title_english'] }}
					</ng-container>
				</h2>

			</header>
			<div class="description">
				<ng-container *ngIf="_selectedLanguage == 'is'">
					<div [innerHtml]="practiceArea.content.rendered"></div>
				</ng-container>
				<ng-container *ngIf="_selectedLanguage == 'en'">
					<div [innerHtml]="practiceArea.acf['description_english']"></div>
				</ng-container>
			</div>
		</article>
		<div class="m-t-20 pull-right">
			<a href="javascript:;" [routerLink]="'/practice-areas'" class="btn btn-primary pull-right">{{ 'PRACTICEAREALIST' | translate }}</a>
		</div>

	</div>
</main>