import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.less'],
})
export class BannerComponent implements OnInit {

	@Input()
	title: string

	@Input()
	image: string

	@Input()
	backgroundStyle: Object

	@Input()
	isLarge: Boolean

	@Input()
	hasShade: Boolean

	constructor() {
	}

	ngOnInit() {

	}

}
