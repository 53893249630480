import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Post } from './post';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private _wpBase = environment.apiBaseUrl + 'wp-json/wp/v2/';

  posts: Post[] = [];
  pages: string;
  allPosts: string;

  
  constructor(private httpClient: HttpClient) { }

  getPosts = (lang: string, page: number = 1, pageSize: number = 10): Observable<Post[]> => {

    this.posts = [];

    const currLang = lang == "en" ? "news_english" : "news_icelandic";

    let options = {
      observe: "response" as 'body',
      params: {
        per_page: ''+pageSize,
        page: ''+page,
      }
    }


    return this.httpClient.get(`${this._wpBase}posts?ilter[category_name]=${currLang}`, options).pipe(
      map(resp => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.allPosts = resp['headers'].get('x-wp-total');
        let postsResp = resp['body'];
        
        postsResp.forEach(post => {
          const { title, date, content, slug, excerpt } = post;
          this.posts.push({ title: title.rendered, date, content: content.rendered, slug, excerpt });
        });

        return this.posts;
      })
    );

  }

  public getPost = (slug: string) => this.httpClient.get(this._wpBase + `posts?slug=${slug}`)
	
}
