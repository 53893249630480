import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PracticeAreasService } from '../practice-areas.service';

@Component({
	selector: 'app-practice-areas',
	templateUrl: './practice-areas-list.component.html',
	styleUrls: ['./practice-areas-list.component.less'],
	providers: [PracticeAreasService]
})
export class PracticeAreasListComponent implements OnInit {

	public _selectedLanguage: string;
	bannerText: string;
	backgroundStyle: Object;
	practiceAreas = []

	constructor(private practiceAreasService: PracticeAreasService,
		private router: Router,
		public translate: TranslateService ) {
		this._selectedLanguage = translate.currentLang;
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('PRACTICEAREAS')
		});
	}


	public getPracticeAreas = () => this.practiceAreasService.getPracticeAreas(-1).subscribe((data: any[]) => {
		this.practiceAreas = data
	})

	public selectPracticeArea = slug => this.router.navigate([this.translate.currentLang + '/' + this.translate.instant('ROUTES.practice-areas'), slug]);

	ngOnInit() {
		this.bannerText = this.translate.instant('PRACTICEAREAS')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/stolar2.jpg)',
            'background-position': '50%',
            'background-attachment': 'inherit',
            'background-size': 'auto',
        }
		this.getPracticeAreas();
	}

}
