import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PostService } from 'src/app/services/posts/post.service';
import { TranslateService } from '@ngx-translate/core';
import { Post } from 'src/app/services/posts/post';
import { PageEvent } from '@angular/material/paginator';

@Component({
	selector: 'app-post-list',
	templateUrl: './post-list.component.html',
	styleUrls: ['./post-list.component.less'],
	providers: [PostService]
})
export class PostListComponent implements OnInit {
	private _selectedLanguage: string;
	bannerText: string;
	backgroundStyle: Object;
	posts: Post[] = [];
	postCount = null;
	page = 1;
	pageSize = 5;
	pageIndex: number;

	constructor(
		private postService: PostService,
		private router: Router,
		private translate: TranslateService) {
		this._selectedLanguage = translate.currentLang;
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('NEWS.TITLE')
        });
	}

	public getPosts = (page: number, pageSize: number) => {
		this.postService.getPosts(this._selectedLanguage, page, pageSize).subscribe((data: Post[]) => {
			this.postCount = this.postService.allPosts;
			this.posts = data;
		})
	}

	public selectPost = (slug: string) => {
		this.router.navigate([this.translate.currentLang + '/' + this.translate.instant('POST'), slug]);
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('NEWS.TITLE')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/logosticker2.jpg)',
        }
		this.getPosts(1, this.pageSize);
		this.pageIndex = 0;
	}

	onChangePage(pe: PageEvent) {
		console.log(pe.pageIndex);
		this.getPosts(pe.pageIndex+1, pe.pageSize);
		this.pageIndex = pe.pageIndex;
	}

}
