import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeService } from '../employee-service';

@Component({
	selector: 'app-employee-detail',
	templateUrl: './employee-detail.component.html',
	styleUrls: ['./employee-detail.component.less'],
	providers: [EmployeeService]
})
export class EmployeeDetailComponent implements OnInit {

	public _selectedLanguage: string;
	employee: any;
	bannerText: string;
	backgroundStyle: Object;

	constructor(private employeeService: EmployeeService,
		private route: ActivatedRoute,
		public translate: TranslateService) {

		this._selectedLanguage = translate.currentLang
		this.translate.onLangChange.subscribe((params: { lang: string, translations: any }) => {
			this._selectedLanguage = params.lang;
			this.bannerText = this.translate.instant('EMPLOYEES.EMPLOYEES')
		});
	}

	public getEmployee = (slug:string) => {
		this.employeeService.getEmployee(slug).subscribe((data: any[]) => this.employee = data[0])
	}

	ngOnInit() {
		this.bannerText = this.translate.instant('EMPLOYEES.EMPLOYEES')
		this.backgroundStyle = {
            'background-image': 'url(../../assets/images/backgrounds/kufl.jpg)',
		};
		const slug = this.route.snapshot.paramMap.get('slug');
		this.getEmployee(slug)
	}

}
