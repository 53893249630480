<div id="banner" [ngClass]="{'large': isLarge }">
	<div class="banner-image" [ngStyle]="backgroundStyle" [ngClass]="{ 'has-shade': hasShade }">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-10 col-lg-6">
					<div class="banner-overlay animated">
						<h1 class="special-title">{{ title }}</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>